import { extendTheme } from '@chakra-ui/react';
import { createTheme } from '@material-ui/core';

const poppinsFontFamily = {
  fontFamily: '"Poppins"',
};

const robotoFontFamily = {
  fontFamily: '"Roboto"',
};

const StimulusTheme = createTheme({
  typography: {
    fontFamily: '"Poppins","Roboto"',
    h1: poppinsFontFamily,
    h2: poppinsFontFamily,
    h3: poppinsFontFamily,
    h4: poppinsFontFamily,
    h5: poppinsFontFamily,
    h6: poppinsFontFamily,
    subtitle1: poppinsFontFamily,
    subtitle2: robotoFontFamily,
    body1: robotoFontFamily,
    body2: robotoFontFamily,
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#1BB062',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    MuiContainer: {
      maxWidthXl: {
        paddingLeft: '64px',
        paddingRight: '64px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiSlider: {
      thumb: { color: '#C2C2C2' },
      track: { color: 'black' },
      rail: { color: '#DBDBDB' },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#158048',
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: '2.5rem',
      },
    },
  },
});

export const StimulusChakraTheme = extendTheme({
  colors: {
    primary: '#2A2A28',
    secondary: '#C62F40',
    event: {
      red: '#BCE6CE',
      green: '#EB496933',
      gray: '#F1F1F1',
    },
    alert: {
      red: '#C62F40',
      green: '#E9F8ED',
      yellow: '#FFF8E5',
    },
    green: {
      100: '#C7EBD9',
      200: '#B0E2C8',
      300: '#8AD5AE',
      400: '#5FC891',
      500: '#15844B',
      600: '#12814B',
    },
    ambient: {
      100: '#F9FEFB',
      200: '#FAFBF3',
      300: '#F1F1E8',
      400: '#F5F5D9',
      500: '#D2D7BB',
    },
    gray: {
      100: '#919482',
      200: '#7A7D72',
      300: '#666A5F',
      400: '#4D4F4A',
      500: '#2A2A28',
      600: '#1F1F1E',
      700: '#1A1A19',
    },
    lightgray: {
      100: '#D5D5D5',
    },
    pink: { 100: '#E6949E' },
    blue: {
      100: '#E9F3F3',
      200: '#11B2BC',
      300: '#11B2BC',
    },
    gradient: {
      iconbutton:
        'linear-gradient(179.97deg, rgba(176, 226, 187, 0.375) 0.03%, rgba(146, 214, 193, 0.375) 99.97%), #FFFFFF',
      border: 'linear-gradient(90deg, #F4F4F4 0%, rgba(249, 249, 249, 0) 95%)',
    },
    menu: {
      company_category: '#E9F8ED',
    },
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  textStyles: {
    h1: {
      fontSize: ['34px', '34px'],
      fontWeight: '500',
      lineHeight: '51px',
      color: 'primary',
    },
    h1_profile: {
      fontSize: ['28px', '28px'],
      fontWeight: '500',
      lineHeight: '42px',
      color: 'primary',
    },
    h2: {
      fontSize: ['18px', '18px'],
      fontWeight: 400,
      lineHeight: '27px',
      color: 'primary',
    },
    h3: {
      fontSize: ['16px', '16px'],
      fontWeight: 400,
      lineHeight: '24px',
      color: '#002739',
    },
    h4: {
      fontSize: ['14px', '14px'],
      fontWeight: 600,
      lineHeight: '21px',
      color: 'primary',
    },
    h5: {
      fontSize: ['12px', '12px'],
      fontWeight: 600,
      lineHeight: '18px',
      color: 'primary',
    },
    h6: {
      fontSize: ['11px', '11px'],
      fontWeight: 600,
      lineHeight: '16px',
      color: '#666666',
    },
    body: {
      fontSize: ['14px', '14px'],
      fontWeight: 400,
      lineHeight: '21px',
      color: 'primary',
    },
    fieldHelperText: {
      fontWeight: 400,
      fontSize: ['13px', '13px'],
      lineHeight: '19px',
      color: '#5C5C5C',
    },
    circleBgNumbers: {
      fontWeight: 400,
      color: '#5C5C5C',
      mr: '5px',
      bg: 'linear-gradient(179.97deg, rgba(176, 226, 187, 0.375) 0.03%, rgba(146, 214, 193, 0.375) 99.97%), #FFFFFF',
      p: '5px',
      fontSize: '11px',
      borderRadius: '50%',
      w: '24px',
      h: '24px',
      lineHeight: '15px',
      textAlign: 'center',
    },
    inRowTooltip: {
      fontWeight: 400,
      fontSize: ['11px', '11px'],
      lineHeight: '15px',
      letterSpacing: '-0.25px',
      color: 'primary',
    },
    filterFieldHeading: {
      fontWeight: 400,
      fontSize: ['12px', '12px'],
      lineHeight: '18px',
      color: 'primary',
    },
    searchTagLinks: {
      fontWeight: 600,
      fontSize: ['13px', '13px'],
      lineHeight: '19px',
      textDecorationLine: 'underline',
    },
    miniTextLink: {
      fontWeight: 400,
      fontSize: ['11px', '11px'],
      lineHeight: '15px',
      textDecorationLine: 'underline',
      color: '#666666',
    },
    tableSubInfo: {
      fontWeight: 600,
      fontSize: ['12px', '12px'],
      lineHeight: '18px',
      color: 'primary',
    },
    tableSubInfoSecondary: {
      fontWeight: 400,
      fontSize: ['12px', '12px'],
      lineHeight: '18px',
      color: 'primary',
    },
    textLink: {
      fontWeight: 500,
      fontSize: ['13px', '13px'],
      lineHeight: '19px',
      textDecorationLine: 'underline',
      color: 'primary',
      cursor: 'pointer',
    },
    horizontalTabs: {
      fontSize: ['16px', '16px'],
      lineHeight: '24px',
      fontWeight: 600,
    },
    verticalTabs: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '22px',
    },
    pagination: {
      fontSize: ['11px', '11px'],
      fontWeight: 300,
      lineHeight: '15px',
      color: 'primary',
    },
    squareSolidActive: {
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '19px',
      color: '#12814B',
    },
    squareGradient: {
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '19px',
    },
    sidenavCategory: {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '22px',
      color: 'primary',
    },
    sidenavParent: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
    },
    sidenavChild: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: 'primary',
    },
  },
  components: {
    Checkbox: {
      variants: {
        stimulus: {
          control: {
            border: '1px solid black',
            _checked: {
              bg: '#2A2A28',
              border: '1px solid black',
            },
          },
        },
      },
    },
    Button: {
      variants: {
        active: {
          color: 'primary',
          bg: 'linear-gradient(179.97deg, rgba(176, 226, 187, 0.75) 0.03%, rgba(146, 214, 193, 0.75) 99.97%), #FFFFFF',
        },
        rounded: {
          color: 'primary',
          fontWeight: 600,
          borderRadius: '28px',
          bg: 'linear-gradient(179.97deg, rgba(176, 226, 187, 0.375) 0.03%, rgba(146, 214, 193, 0.375) 99.97%), #FFFFFF',
        },
        quickAction: {
          maxHeight: '26px',
          bg: 'gradient.iconbutton',
          borderRadius: '15px',
          fontWeight: '600',
          fontSize: '11px',
          lineHeight: '16px',
          letterSpacing: '0.5px',
          textTransform: 'uppercase',
          color: 'primary',
        },
      },
    },
    Text: {
      variants: {
        detailslink: {
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '11px',
          lineHeight: '16px',
          textDecorationLine: 'underline',
          color: '#666666',
        },
      },
    },
    Switch: {
      variants: {
        stimulus: {
          thumb: {
            border: '1px solid black',
            mt: '-2px',
            w: '18px',
            h: '18px',
            ml: '-2px',
            _checked: {
              ml: '4px !important',
            },
          },
          track: {
            bg: '#FFFFFF',
            border: '1px solid black',
            w: '34px',
            h: '14px',
            _checked: {
              bg: '#2A2A28',
            },
          },
        },
      },
    },
  },
});
export default StimulusTheme;
