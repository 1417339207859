import { Flex, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DiscoveryState, setMinorityOwnership } from '../../../stores/features';
import { Checkbox } from '../../GenericComponents';
import { useQuery } from '@apollo/client';
import CompanyQueries from '../../../graphql/Queries/CompanyQueries';

const { GET_COMPANY_MINORITY_OWNERSHIP } = CompanyQueries;

const MinorityFilters = () => {
  const { t } = useTranslation();
  const minorityOwnership = useSelector((state: { discovery: DiscoveryState }) => state.discovery?.minorityOwnership);
  const dispatch = useDispatch();
  const handleToggleItem = (target: string) => {
    const newItems =
      minorityOwnership.indexOf(target) > -1
        ? minorityOwnership.filter((item) => item !== target)
        : [...minorityOwnership, target];
    dispatch(setMinorityOwnership(newItems));
  };
  const { data: cachedMinoryOwnership } = useQuery(GET_COMPANY_MINORITY_OWNERSHIP, {
    fetchPolicy: 'cache-first',
  });
  const availableMinorityOwnership =
    cachedMinoryOwnership?.getCompanyMinoritiesDiverseOwnership?.minorityOwnership ?? [];

  const halfLength = Math.ceil(availableMinorityOwnership.length / 2);
  const firstItems = availableMinorityOwnership.slice(0, halfLength);
  const remainingItems = availableMinorityOwnership.slice(halfLength);

  return (
    <>
      <Text as="h4" mt="0.5rem" textStyle="h4">
        {t('Minority')}
      </Text>
      <Flex direction="row" mt=".5rem">
        <Stack flex={1} spacing={3} direction="column">
          {firstItems.map((item: string) => (
            <Stack direction="row" alignItems="center" key={item} onClick={() => handleToggleItem(item)}>
              <Checkbox checked={minorityOwnership.indexOf(item) > -1} />
              <Text textStyle="body">{t(item)}</Text>
            </Stack>
          ))}
        </Stack>
        <Stack flex={1} spacing={3} direction="column">
          {remainingItems.map((item: string) => (
            <Stack direction="row" alignItems="center" key={item} onClick={() => handleToggleItem(item)}>
              <Checkbox checked={minorityOwnership.indexOf(item) > -1} />
              <Text textStyle="body">{t(item)}</Text>
            </Stack>
          ))}
        </Stack>
      </Flex>
    </>
  );
};

export default MinorityFilters;
